import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin: 0 auto 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TimelinePoint = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.text};
  height: 12px;
  width: 12px;
  margin: 0.5rem auto;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 100%;
    width: 100vw;
    height: 1px;
    border-top: 2px dashed #dddddd;
  }
`;

export const Price = styled.p`
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  font-weight: 500;
  margin: 0;
`;

export const TimelineItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  & p:not(${Price}) {
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.colors.textLight};
  }

  &:first-child {
    text-align: left;

    & ${TimelinePoint} {
      margin-left: 0;
      margin-right: auto;

      &:before {
        display: none;
      }
    }
  }

  &:last-child {
    text-align: right;
    & ${TimelinePoint} {
      margin-left: auto;
      margin-right: 0;

      &:after {
        top: calc(50% - 3px);
        left: 100%;
        height: 6px;
        background: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const TimelineDate = styled.p`
  font-size: 1rem;
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textLight};
`;
