import { ReactElement } from "react";
import { useTranslation } from "next-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isToday, differenceInCalendarDays } from "date-fns";
import { useBrand } from "@hooks/useBrand";
import { useOrder } from "@hooks/useOrder";
import { currencyFormatter } from "@utils/currencyFormat";
import { useLocale } from "@hooks/useLocale";
import Wrapper, { TimelineItem, TimelineDate, TimelinePoint, Price } from "./Timeline.style";

export const Timeline = (): ReactElement => {
  const { t } = useTranslation(["common"]);
  const { data, paymentPlan, isLoading } = useOrder();
  const { locale } = useLocale();
  const { brand } = useBrand();

  if (data && paymentPlan?.scheduled_payments && !isLoading) {
    let scheduledPayments = paymentPlan.scheduled_payments;

    if (!scheduledPayments.some(({ date }) => isToday(new Date(date)))) {
      scheduledPayments = [
        {
          amount: 0,
          date: data.order_date || new Date().toISOString().substring(0, 10),
          // TODO: when multiple payment methods are available then the below logic will need to be change
          allowed_payment_methods: scheduledPayments[0].allowed_payment_methods,
        },
        ...scheduledPayments,
      ];
    }

    return (
      <Wrapper>
        {scheduledPayments.map((payment, index) => {
          const date = new Date(payment.date);

          return (
            <TimelineItem data-testid="timelineItem" key={`${payment.date}${payment.amount}`}>
              <Price>
                <strong>{currencyFormatter(locale, payment.amount, data.currency)}</strong>
              </Price>
              <TimelineDate>
                <small>
                  {isToday(date) && t("today", { context: brand })}
                  {!isToday(date) &&
                    scheduledPayments.length - 1 !== index &&
                    `${date.toLocaleDateString(locale)}`}
                  {!isToday(date) &&
                    scheduledPayments.length - 1 === index &&
                    `${date.toLocaleDateString(locale)} (${differenceInCalendarDays(
                      date,
                      new Date()
                    )} ${t("days").toLocaleLowerCase()})`}
                </small>
              </TimelineDate>
              <TimelinePoint />
            </TimelineItem>
          );
        })}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TimelineItem data-testid="timelineItem">
        <Price>
          <strong>
            <Skeleton width={60} />
          </strong>
        </Price>
        <TimelineDate>
          <small>
            <Skeleton width={75} />
          </small>
        </TimelineDate>
        <TimelinePoint />
      </TimelineItem>
      <TimelineItem data-testid="timelineItem">
        <Price>
          <strong>
            <Skeleton width={60} />
          </strong>
        </Price>
        <TimelineDate>
          <small>
            <Skeleton width={125} />
          </small>
        </TimelineDate>
        <TimelinePoint />
      </TimelineItem>
    </Wrapper>
  );
};
