export const currencyFormatter = (
  locale: string,
  amount: number,
  currency: string,
  optionalDecimal = false
) => {
  if (!optionalDecimal) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(amount / 100);
  }

  if (amount % 1 === 0) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount / 100);
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount / 100);
};
